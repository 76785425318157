var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form form--narrow" }, [
    _c("h2", { staticClass: "form__heading" }, [_vm._v("Log in")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass:
          "form__group form__group--center form__button-group form__button-group--full-width-with-margin",
        attrs: { method: "post", action: _vm.externalLoginCallbackUrl },
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "returnUrl" },
          domProps: {
            value:
              _vm.mode == "popup" ? _vm.$route.fullPath : _vm.$router.returnUrl,
          },
        }),
        _vm._v(" "),
        _c(
          "fs-button-main",
          {
            attrs: {
              long: true,
              size: "large",
              type: "submit",
              name: "provider",
              value: "LinkedIn",
              title: "Log in using your LinkedIn account",
            },
          },
          [
            _c("FsButtonIcon", {
              attrs: { small: true, icon: _vm.svg.linkedIn },
            }),
            _vm._v("Log in with LinkedIn\n    "),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.logIn()
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__group" },
          [
            _c("Field", {
              ref: "email",
              attrs: {
                type: "email",
                name: "email",
                label: "E-mail",
                validation: _vm.emailValidation,
              },
              model: {
                value: _vm.loginData.email,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.loginData,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "loginData.email",
              },
            }),
            _vm._v(" "),
            _c("Password", {
              ref: "password",
              attrs: {
                name: "password",
                label: "Password",
                validation: _vm.passwordValidation,
                toggled: _vm._toggled,
              },
              on: {
                "update:toggled": function ($event) {
                  _vm._toggled = $event
                },
              },
              model: {
                value: _vm.loginData.password,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.loginData,
                    "password",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "loginData.password",
              },
            }),
            _vm._v(" "),
            _vm.needMfa
              ? _c("Field", {
                  ref: "twoFactorCode",
                  attrs: {
                    type: "text",
                    name: "twoFactorCode",
                    label: "2FA code",
                    validation: _vm.emailValidation,
                  },
                  model: {
                    value: _vm.loginData.twoFactorCode,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginData,
                        "twoFactorCode",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginData.twoFactorCode",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.loginErrors.unknownError
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message form__message--error" }, [
                _c("span", {
                  staticClass: "form__message-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.error) },
                }),
                _vm._v("\n        Unknown error, please try again!\n      "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.needMfa
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message" }, [
                _vm._v(
                  "Need to provide 2FA code. Please open up your authenticator app to find the code."
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--right" },
          [
            _c(
              "router-link",
              {
                staticClass: "link link--button form__link",
                attrs: {
                  event: _vm.mode == "popup" ? "" : "click",
                  to: { name: "forgotPassword" },
                },
                nativeOn: {
                  click: function ($event) {
                    return (function () {
                      return _vm.mode == "popup"
                        ? _vm.$parent.open("forgotPassword")
                        : ""
                    })($event)
                  },
                },
              },
              [_vm._v("Forgot password?")]
            ),
            _vm._v(" "),
            _c("fs-button-main", { attrs: { loading: _vm.loading } }, [
              _vm._v("Log in"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form__group form__group--center form__button-group--full-width-with-margin",
          },
          [
            _c(
              "fs-button-main",
              {
                attrs: {
                  styling: "outlined",
                  size: "large",
                  long: true,
                  event: _vm.mode == "popup" ? "" : "click",
                  to: _vm.signUpLocation,
                },
                nativeOn: {
                  click: function ($event) {
                    return (function () {
                      return _vm.mode == "popup"
                        ? _vm.$parent.open("signUp")
                        : ""
                    })($event)
                  },
                },
              },
              [_vm._v("\n        Register account\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form__group form__group--center" }, [
      _c("span", { staticClass: "form__note-header" }, [_vm._v("Or")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "form__group form__group--new-section form__group--center",
      },
      [
        _c("strong", { staticClass: "form__subtitle" }, [
          _vm._v("Don't have an account?"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }